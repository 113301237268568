//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {queryTact} from '@/apis/tool'
export default {
	data: ()=> ({
		des: '',
		dep: '',
		dataSource: [],
		inRNBox: false,
		navigationBarBackgroundColor: '#437AFF',
	}),
	created() {
		this.inRNBox = window.ReactNativeWebView || !!this.$route.query.inRNBox || false
		if(this.inRNBox) {
			this.navigationBarBackgroundColor = '#' + (this.$route.query.navigationBarBackgroundColor || '154B79')
		}
		this.des = this.$route.query.des || 'LAX'
		this.dep = this.$route.query.dep || 'PEK'
	},
	mounted() {
		this._getList({
			dep: this.dep,
			des: this.des
		})
	},
	methods: {
		formatter(value) {
			  // 过滤输入的数字
			let s = value.replace(/[^\w\.\/]/ig,'')
				s = s.toUpperCase().substring(0,5)
      		return s
    	},
		async _getList({dep, des}) {
			let {data} = await queryTact({dep, des})
			this.dataSource = data || []
		},
		async submit() {
			this.des = this.des.toLocaleUpperCase()
			this.dep = this.dep.toLocaleUpperCase()
			let reg = /^[A-Z]{3}$/
			if(!reg.test(this.dep) || !reg.test(this.des)) {
				this.$toast('输入有误，请仔细核对')
				return
			}
			await this._getList({
				des: this.des,
				dep: this.dep
			})
		}
	}
}
